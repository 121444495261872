import React from 'react';

import StaticPageLayout from '../components/staticPageLayout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <StaticPageLayout title="404">
    <SEO title="404: Not found" />
    <div>
      <p>Strona nie została znaleziona.</p>
    </div>
  </StaticPageLayout>
);

export default NotFoundPage;
